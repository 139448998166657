export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5001';

// Redirecting to the backend's Google OAuth URL
export const redirectToLogin = () => {
  window.location.href = `${SERVER_URL}/api/login`;
};

// Define a type for the options parameter of the API request
interface ApiRequestOptions extends RequestInit {
  headers?: HeadersInit;
}

// Define a type for the expected response (if known, otherwise use `any` for flexibility)
type ApiResponse<T> = Promise<T>;

/**
 * A reusable function for making authenticated API requests.
 *
 * @param endpoint The API endpoint to call (e.g., `/google-calendars`).
 * @param jwt The JSON Web Token for authentication.
 * @param options Optional configuration for the fetch request (e.g., method, body).
 *
 * @returns The parsed JSON data from the API response.
 * @throws An error if the request fails or returns a non-2xx response.
 */
export const apiRequest = async <T = any>(
  endpoint: string,
  jwt: string | null,
  options: ApiRequestOptions = {}
): Promise<ApiResponse<T>> => {
  if (!jwt) {
    throw new Error('No JWT available for making API requests');
  }

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwt}`,
    ...options.headers, // Merge in any custom headers provided via options
  };

  const response = await fetch(`${SERVER_URL}${endpoint}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    // Redirect to login if needed
    if (response.status === 401 || response.status === 403) {
      redirectToLogin();
    }
    throw new Error(`Error: ${response.statusText}`);
  }

  return response.json() as Promise<T>; // Return the response, typed as T
};
