import { apiRequest, redirectToLogin } from './api';
import { useAuth } from './useAuth';
import React, { useState, useEffect, useCallback } from "react";
import ReactTimeAgo from "react-time-ago";
import { Calendar } from "./types";

const App: React.FC = () => {
  const { jwt, handleLogout } = useAuth();
  const [events, setEvents] = useState<any[]>([]);
  const [stats, setStats] = useState<{ totalRecurringEvents: number, totalInstancesPerYear: number | undefined}>();
  const [calendars, setCalendars] = useState<any[]>([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>("recurring");
  const [sortOption, setSortOption] = useState<string>("nextDate");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const fetchCalendars = useCallback(async () => {
    if (!jwt) return;
    setLoading(true);
    try {
      console.log('fetchCalendars, jwt=', jwt);
      const data = await apiRequest('/api/calendars', jwt);

      console.log(data);
      const calendars: Calendar[] = data.calendars;

      calendars.sort((a: Calendar, b: Calendar) => {
        if (a.primary && !b.primary) return -1;
        if (!a.primary && b.primary) return 1;
        return a.summary.toLowerCase().localeCompare(b.summary.toLowerCase());
      });

      setCalendars(calendars);
      if (calendars.length > 0) setSelectedCalendarId(calendars[0].id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [jwt]);

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars]);

  const handleSort = (column: string) => {
    setSortOption(column);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const fetchEvents = useCallback(async (pageToken?: string) => {
    console.log('fetchEvents', selectedCalendarId);
    if (!selectedCalendarId) return;
    setLoading(true);
    setError(null);
    setEvents([]);

    try {
      const params = new URLSearchParams({
        calendarId: selectedCalendarId,
        orderBy: sortOption,
        filter: filter,
        sortOrder: sortOrder,
      });
      // if (pageToken) {
      //   params.append('pageToken', pageToken);
      // }

      const data = await apiRequest(`/api/events?${params}`, jwt);
      console.log(data);

      setEvents((prevEvents) => {
        let newEvents = [...prevEvents, ...data.events];
        newEvents = Array.from(
          new Map(newEvents.map((event) => [event.id, event])).values()
        );

        console.log(`Fetched ${newEvents.length} events`);
        newEvents.forEach((event) => {
          console.log(event.summary, event);
        });

        return newEvents;
      });

      setStats(data.stats);

      // if (data.nextPageToken) {
      //   await fetchEvents(data.nextPageToken);
      // }
    } catch (error: any) {
      console.error(error);
      setError(error.message || "Failed to fetch events. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [selectedCalendarId, sortOption, filter, sortOrder, jwt]);
  useEffect(() => {
    setEvents([]);
    fetchEvents();
  }, [selectedCalendarId, sortOption, filter, fetchEvents, jwt]);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      {!jwt ? (
        <button
          onClick={redirectToLogin}
          className="m-4 px-7 py-3 bg-green-500 text-white rounded-md"
        >
          Login
        </button>
      ) : (<>
        <header className="p-4 flex justify-between items-center">
          <button
            onClick={handleLogout}
            className="m-4 px-7 py-2 bg-red-500 text-white  rounded-md"
            >
            Logout
          </button>
        </header>
        <main className="p-4">
          <div className="flex flex-row justify-between items-center mb-4">
            <p className="text-gray-600">
              Total Recurring Events: {stats?.totalRecurringEvents}
            </p>
            <p className="text-gray-600">
              Total Instances Per Year: {stats?.totalInstancesPerYear}
            </p>
          </div>
          <div className="flex justify-between items-center mb-4 font-mediums">
            <label className="mr-2">
              Filter by:
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="ml-2 bg-white border border-gray-300 rounded px-3 py-1 cursor-pointer"
                >
                <option value="recurring">Recurring</option>
                <option value="all">All</option>
                <option value="non-recurring">Non-Recurring</option>
              </select>
            </label>
            <select
              value={selectedCalendarId || ""}
              onChange={(e) => setSelectedCalendarId(e.target.value)}
              className="ml-2 bg-white border border-gray-300 rounded px-3 py-1 cursor-pointer"
              >
              {calendars.map((calendar: any) => (
                <option key={calendar.id} value={calendar.id}>
                  {calendar.summary}
                </option>
              ))}
            </select>
          </div>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('summary')}>
                  Name of Meeting {sortOption === 'summary' && (sortOrder === 'asc' ? '↑' : '↓')}
                </th>
                <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('frequency')}>
                  Frequency {sortOption === 'frequency' && (sortOrder === 'asc' ? '↑' : '↓')}
                </th>
                <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('lastDate')}>
                  Last Date {sortOption === 'lastDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                </th>
                <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('nextDate')}>
                  Next Date {sortOption === 'nextDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                </th>
                <th className="py-2 px-4 border-b">Time Between Instances</th>
                <th className="py-2 px-4 border-b">Events in Next 12 Months</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                const isOneTimeEvent = event.plainEnglishFrequency === 'one-time event';
                return <tr key={event.id} className={`hover:bg-gray-100 ${isOneTimeEvent ? 'text-gray-300 italic' : ''}`}>
                  <td className="py-2 px-4 border-b">
                    {(event.oneOnOne) ? (
                      <img src={event.oneOnOne.avatar} alt={event.oneOnOne.name} title={event.oneOnOne.name} className="inline w-6 h-6 rounded-full mr-2" />
                    ) : (
                      <span title={`${event.numAttendees} attendees`} className="bg-gray-200 text-gray-700 rounded-full px-2 py-1 text-xs mr-2">
                        {event.numAttendees}
                      </span>
                    )}
                    <a href={event.htmlLink} rel="noopener" target="_blank">{event.summary}</a>
                  </td>
                  <td className="py-2 px-4 border-b">
                    {event.plainEnglishFrequency}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {!isOneTimeEvent && event.lastInstanceDate ? (
                      <ReactTimeAgo
                      date={new Date(event.lastInstanceDate).getTime()}
                      locale="en-US"
                      />
                    ) : (
                      'Never'
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {event.nextInstanceDate ? (
                      <ReactTimeAgo
                      date={new Date(event.nextInstanceDate).getTime()}
                      locale="en-US"
                      />
                    ) : (
                      "???"
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {event.timeBetweenInstances}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {event.instancesInNext12Months}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </main>
      </>
    )}
    </div>
  );
};

export default App;