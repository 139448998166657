import { useState, useEffect } from 'react';

// Custom hook to manage JWT (authentication)
export const useAuth = () => {
  const [jwt, setJwt] = useState<string | null>(null);

  useEffect(() => {
    // Load the JWT from localStorage when the app starts
    const storedJwt = localStorage.getItem('jwt');
    if (storedJwt) {
      setJwt(storedJwt);
      console.log('storedJwt', storedJwt);
    }

    // Check if the URL contains a JWT (from OAuth callback)
    const params = new URLSearchParams(window.location.search);
    const jwtFromUrl = params.get('jwt');
    if (jwtFromUrl) {
      // Store the JWT in localStorage and state
      localStorage.setItem('jwt', jwtFromUrl);
      setJwt(jwtFromUrl);
      console.log('settingJwtFromQS', jwtFromUrl);
      window.history.replaceState({}, document.title, '/'); // Clear query params
    }
  }, []);

  // Handle logout by clearing the JWT from localStorage and state
  const handleLogout = () => {
    localStorage.removeItem('jwt');
    setJwt(null);
    //   // TODO We probably need to pass JWT to the server to revoke it there as well
    //   localStorage.removeItem('jwt');
    //   setJwt(null);
    //   setEvents([]);
    //   document.location = `${SERVER_URL}/api/logout`;
  };

  return { jwt, handleLogout };
};